:root {
  --color-dark: #222222;
  --color-light: #e2e2e2;
  --color-scheme-background: var(--color-light);
  --color-scheme-text-color: var(--color-dark);
}

* {
  touch-action: manipulation;
}

@media (prefers-color-scheme: light) {
  :root {
    --color-scheme-background: var(--color-light);
    --color-scheme-text-color: var(--color-dark);
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-scheme-background: var(--color-dark);
    --color-scheme-text-color: var(--color-light);
  }
}

/* Usage */
html {
  background: var(--color-scheme-background);
  color: var(--color-scheme-text-color);
}

body {
  margin: 0;
  font-family: sans-serif;
}

/* orientation lock */
@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}
